<template>
    <v-card height="100%">
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit description</v-toolbar-title>

            <v-spacer />
            
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <RichTextEditor v-model="descriptionLocal" />                    
    </v-card>
</template>

<script>
import RichTextEditor from "../../components/RichTextEditor.vue";
import tasksAgent from "./tasksAgent";

export default {
    components: {
        RichTextEditor,
    },

    props: {
        id: String,
        description: String,
    },
    
    data () {
        return {
            descriptionLocal: this.description,
        };
    },

    methods: {
        save () {
            var self = this;

            var l = this.$coreUi.loading();
            
            // Load workItem
            tasksAgent.getTask(this.id).then((result) => {

                // Set description and save
                result.workItem.description = this.descriptionLocal;

                // HACK: Call fails (bad request) unless we clear these
                result.aspectData = {};
                // delete result.aspectData.instructionB2BDetails
                // delete result.aspectData.odsEntityPicker

                tasksAgent.saveTask(this.id, result).then(() => {

                    l.dismiss();
                    self.close(true);

                }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>

<style>
.tiptap-vuetify-editor {
    height: calc(100% - 94px);
}
.theme--light.v-sheet .tiptap-vuetify-editor > .v-card {
    height: 100%;
}
.tiptap-vuetify-editor__content {
    height: calc(100% - 74px);
}
.tiptap-vuetify-editor__content .ProseMirror {
    min-height: calc(100% - 40px);
}
</style>